<template>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <Toast />
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">
                <Button
                  label="Nuevo"
                  icon="pi pi-plus"
                  class="p-button-success mr-2"
                  @click="openNew"
                /> 
              </div>
            </template>
  
            <template v-slot:end>
              <Button
                label="Exportar"
                icon="pi pi-upload"
                class="p-button-help"
                @click="exportCSV($event)"
              />
            </template>
          </Toolbar>
  
          <DataTable
            ref="dt"
            :value="products"
            dataKey="id"
            :paginator="true"
            :rows="10"
            :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Clientes"
            responsiveLayout="scroll"
          >
            <template #header>
              <div
                class="
                  flex flex-column
                  md:flex-row md:justify-content-between md:align-items-center
                "
              >
                <h5 class="m-0">Bancos</h5>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filters['global'].value"
                    placeholder="Buscar..."
                  />
                </span>
              </div>
            </template>
  
            <Column
              field="code"
              header="Sucursal"
              :sortable="true"
              headerStyle="width:14%; min-width:10rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">Sucursal</span>
                {{ slotProps.data.sucursal }}
              </template>
            </Column>
            <Column
              field="name"
              header="Nombre"
              :sortable="true"
              headerStyle="width:14%; min-width:10rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">Nombre</span>
                {{ slotProps.data.nombre }}
              </template>
            </Column> 
            <Column
              field="type_client"
              header="Moneda"
              :sortable="true"
              headerStyle="width:14%; min-width:10rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">Moneda</span>
                {{ monedaType(slotProps.data.moneda) }}
              </template>
            </Column>
            <Column
              field="email"
              header="E-mail"
              :sortable="true"
              headerStyle="width:14%; min-width:10rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">E-mail</span>
                {{ slotProps.data.email }}
              </template>
            </Column>
            <Column
              field="cbu"
              header="CBU"
              :sortable="true"
              headerStyle="width:14%; min-width:10rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">CBU</span>
                {{ slotProps.data.cbu }}
              </template>
            </Column>
            <Column headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-success mr-2"
                  @click="editProduct(slotProps.data)"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-warning mt-2"
                  @click="confirmDeleteProduct(slotProps.data)"
                />
              </template>
            </Column>
          </DataTable>
  
          <Dialog
            v-model:visible="productDialog"
            :style="{ width: '450px' }"
            header="Bancos"
            :modal="true"
            class="p-fluid"
          >  
            <div class="field">
              <label for="name">Nombre del banco</label>
              <InputText
                id="nombre"
                v-model.trim="product.nombre"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !product.nombre }"
              />
              <small class="p-invalid" v-if="submitted && !product.nombre"
                >Nombre es requerido.</small
              >
            </div>
            <div class="field">
              <label for="name">E-mail de contacto</label>
              <InputText
                id="name"
                v-model.trim="product.email"
                autofocus
                :class="{ 'p-invalid': submitted && !product.email }"
              />
              <small class="p-invalid" v-if="submitted && !product.email"
                >E-mail es requerido.</small
              >
            </div>
            <div class="formgrid grid">
              <div class="field col">
                <label for="name">CUIT</label>
                <InputText
                  id="cuit"
                  v-model.trim="product.cuit"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.cuit }"
                />
                <small class="p-invalid" v-if="submitted && !product.cuit"
                  >CUIT es requerido.</small
                >
              </div>

            </div>
            <div class="field">
              <label for="name">Dirección</label>
              <InputText
                id="iibb"
                v-model.trim="product.address"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !product.address }"
              />
              <small class="p-invalid" v-if="submitted && !product.address"
                >Dirección es requerido.</small
              >
            </div>
            <div class="formgrid grid">
              <div class="field col">
                <label for="telefono">Teléfono</label>
                <InputText id="telefono" v-model="product.telefono" />
              </div>
              <div class="field col">
                <label for="sucursal">Sucursal</label>
                <InputNumber id="sucursal" v-model="product.sucursal" integeronly />
              </div>
            </div>
            <div class="formgrid grid">
              <div class="field col">
                <label for="cuenta">Cuenta</label>
                <InputText id="cuenta" v-model="product.cuenta" />
              </div>
              <div class="field col">
                <label for="cbu">CBU</label>
                <InputText id="cbu" v-model="product.cbu" />
              </div>
            </div>
            <div class="field">
              <label class="mb-3">Moneda</label>
              <div class="formgrid grid">
                <div class="field-radiobutton col-6">
                  <RadioButton
                    id="category1"
                    name="moneda"
                    value="1"
                    v-model="product.moneda"
                  />
                  <label for="moneda1">Pesos</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton
                    id="moneda2"
                    name="moneda"
                    value="2"
                    v-model="product.moneda"
                  />
                  <label for="moneda2">Dolares</label>
                </div> 
              </div>
            </div> 
            <template #footer>
              <Button
                label="Cancelar"
                icon="pi pi-times"
                class="p-button-text"
                @click="hideDialog"
              />
              <Button
                label="Guardar"
                icon="pi pi-check"
                class="p-button-text"
                @click="saveProduct"
              />
            </template>
          </Dialog>
  
          <Dialog
            v-model:visible="deleteProductDialog"
            :style="{ width: '450px' }"
            header="Confirmación"
            :modal="true"
          >
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product"
                >Eliminaras a <b>{{ product.name }}</b
                >?</span
              >
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteProductDialog = false"
              />
              <Button
                label="Sí"
                icon="pi pi-check"
                class="p-button-text"
                @click="deleteProduct"
              />
            </template>
          </Dialog>
  
          <Dialog
            v-model:visible="deleteProductsDialog"
            :style="{ width: '450px' }"
            header="Confirm"
            :modal="true"
          >
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product">Seguro?</span>
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteProductsDialog = false"
              />
              <Button
                label="Sí"
                icon="pi pi-check"
                class="p-button-text"
                @click="deleteSelectedProducts"
              />
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import { FilterMatchMode } from "primevue/api";
  import CrudService from "../services/crud.service";
 
  
  export default {
    data() {
      return {
        routeName: null,
        products: null,
        productDialog: false,
        deleteProductDialog: false,
        deleteProductsDialog: false,
        product: {

        },
        selectedProducts: null,
        filters: {},
        submitted: false,
        validationErrors: [],
      };
    },
    
    created() {
      // get route name
      this.routeName = this.$route.name;
      
      this.initFilters();
    },
    mounted() {
      CrudService.getCRUD('api/bancos').then(
        (data) => (this.products = data)
      );
    },
    methods: { 
      monedaType(value) {
        if (value == 1) {
          return "Pesos";
        }
        if (value == 2) {
          return "Dolares";
        } 
        return " - ";
      },
      formatCurrency(value) {
        if (value)
          return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
        return;
      },
      openNew() {
        this.product = {
            moneda: '1'
        };
        this.submitted = false;
        this.productDialog = true;
      },
      hideDialog() {
        this.productDialog = false;
        this.submitted = false;
      },
      displayErrors(errors) {
        
                for (let key in errors) {
  
                  this.validationErrors.push({ "field": key, "message": errors[key] });
  
                  let error = errors[key];
                  this.$toast.add({
                    severity: "error",
                    summary: "Error!",
                    detail: error[0],
                    life: 3000,
                  });
                }
      },
      saveProduct() {
        this.submitted = true;
        if (this.product.nombre.trim()) {
          if (this.product.id) {
            CrudService.updateCRUD('api', 'bancos', this.product).then(
              (data) => {
  
              this.validationErrors = [];
              if (data.status == 400) { 
  
                this.displayErrors(data.data);
  
              } else {
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito!",
                  detail: "Actualizado correctamente.",
                  life: 3000,
                });
                this.products[this.findIndexById(this.product.id)] = this.product;
                this.productDialog = false;
                this.product = {};
              }
            });
          } else {
            CrudService.createCRUD('api', 'bancos', this.product).then(
              (data) => {
                
              this.validationErrors = [];
              if (data.status == 400) { 
  
                this.displayErrors(data.data);
                
                } else {
                  this.products.push(this.product);
                  this.$toast.add({
                    severity: "success",
                    summary: "Éxito!",
                    detail: "Creado correctamente.",
                    life: 3000,
                  });
                  this.productDialog = false;
                  this.product = {};
                }
              }
            );
          }
        }
      },
      editProduct(product) {
        this.product = { ...product };
        this.productDialog = true;
      },
      confirmDeleteProduct(product) {
        this.product = product;
        this.deleteProductDialog = true;
      },
      deleteProduct() {
        
        CrudService.deleteCRUD('api','bancos', this.product.id).then(
          (data) => {
            
            console.log(data.id)
            
            this.products = this.products.filter(
                
              (val) => {
                console.log(val)
                val.id !== data.id
              }
            );

            console.log(this.products)
  
            this.$toast.add({
              severity: "success",
              summary: "Éxito!",
              detail: "Eliminado correctamente.",
              life: 3000,
            });
          }
        );
  
        this.deleteProductDialog = false;
        this.product = {};
      },
      findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
            index = i;
            break;
          }
        }
        return index;
      },
      createId() {
        let id = "";
        var chars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 5; i++) {
          id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
      },
      exportCSV() {
        this.$refs.dt.exportCSV();
      },
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },
      deleteSelectedProducts() {
        this.products = this.products.filter(
          (val) => !this.selectedProducts.includes(val)
        );
        this.deleteProductsDialog = false;
        this.selectedProducts = null;
        this.$toast.add({
          severity: "success",
          summary: "Éxito!",
          detail: "Eliminado correctamente.",
          life: 3000,
        });
      },
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  @import "../assets/demo/badges.scss";
  </style>
  